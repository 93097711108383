<template>
  <ol
    id="submenu"
    data-el="ui-menu-subpages"
    class="relative z-[1] flex text-base md:text-lg"
    :class="[
      {
        'opacity-30 grayscale': props.disabled,
      },
    ]"
  >
    <li
      v-for="(menuItem, index) in menuItems"
      :id="menuItem?.id ?? ''"
      :key="menuItem?.id ?? index"
      class="group relative inline-block cursor-pointer whitespace-nowrap text-center hover:font-bold"
      :data-active="menuItem.active"
      :class="[
        props.listItemClass,
        getStyles(menuItem)?.li,
        {
          'opacity-30 grayscale': menuItem.disabled,
        },
      ]"
      itemprop="itemListElement"
      itemscope
      itemtype="https://schema.org/ListItem"
    >
      <component
        :is="isNuxtLink"
        v-slot="{ navigate = () => null, href = '' }"
        no-prefetch
        :to="menuItem.link"
        custom
      >
        <template v-if="menuItem.link || props.link">
          <button
            :href="href"
            class="absolute z-10 inline-block w-full py-5 text-inherit transition-colors"
            :class="[
              menuItem.active ? 'opacity-0' : 'opacity-100',
              {
                'cursor-not-allowed': menuItem.disabled,
              },
            ]"
            :style="{ paddingLeft: '1.656rem', paddingRight: '1.656rem' }"
            rel="prefetch"
            itemprop="name"
            data-test="ui-menu-subpages-tab"
            @click.prevent="
              menuItem.disabled || props.disabled
                ? ''
                : onClick(menuItem, navigate)
            "
          >
            {{ menuItem.title }}
          </button>
          <!-- In order to prevent content jump when active element gains 'font-bold' - add
               additional span element which changes opacity -->
          <span
            itemprop="name"
            class="relative inline-block w-full px-6 py-5 font-bold transition-colors"
            :class="[
              {
                'opacity-100': menuItem.active,
                'opacity-0': !menuItem.active,
              },
            ]"
          >
            {{ menuItem.title }}
          </span>
        </template>

        <span
          v-else
          itemprop="name"
        >{{ menuItem.title }}</span>
      </component>
    </li>
  </ol>
</template>

<script setup lang="ts">
import { computed, resolveComponent } from 'vue'
import type { MenuItem, UiMenuTabsProps } from './UiMenuTabs.types'
import { ColorVariantEnum } from './UiMenuTabs.types'

const props = withDefaults(defineProps<UiMenuTabsProps>(), {
  link: true,
  nativeLink: false,
  disabled: false,
  listItemClass: () => [],
  colorVariant: 'blue',
})

const emit = defineEmits<{
  (e: 'click', link: MenuItem): void
}>()

const menuItems = computed((): MenuItem[] =>
  props.menu.filter(item => !item?.visible),
)

const isNuxtLink = computed(() =>
  props.link ? resolveComponent('nuxt-link') : 'div',
)

function onClick(item: MenuItem, cb: (...args: any[]) => void) {
  emit('click', item)
  if (item.link) {
    cb(item.link)
  }
}

function getStyles(menuItem: MenuItem) {
  switch (props.colorVariant) {
    case ColorVariantEnum.WHITE: {
      if (menuItem.active) {
        return {
          li: 'text-primary bg-white',
        }
      }

      return {
        li: 'bg-transparent text-white',
      }
    }

    case ColorVariantEnum.BLUE: {
      if (menuItem.active) {
        return {
          li: 'bg-blue-650 text-white',
        }
      }

      return {
        li: 'bg-transparent text-primary',
      }
    }
  }
}
</script>

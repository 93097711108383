export interface MenuItem {
  title: string
  id?: string | number
  link?: string
  disabled?: boolean
  active: boolean
  target?: string
  current?: number
  spacer?: number
  hasSubpages?: number
  visible?: boolean
}

export enum ColorVariantEnum {
  BLUE = 'blue',
  WHITE = 'white',
}

export type ColorVariant = `${ColorVariantEnum}`

export interface UiMenuTabsProps {
  link?: boolean
  nativeLink?: boolean | undefined
  disabled?: boolean
  menu: MenuItem[]
  listItemClass?: string[]
  colorVariant?: ColorVariant
}
